<template>
  <div class="medicaments">
    <ul>
      <li
        v-for="(m, i) in traitementMedicaments"
        :key="i"
      >
        <h6>{{ medicament(m.medoc) }}  <small>({{ m.protocole }})</small></h6>
      </li>
    </ul>
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          medicament(uid){
            let m = this.medicaments.find(item => item.uid === uid)
            if(m) return m.libelle
            return '-'
          }
      },
      computed: {
          ...mapGetters({
            medicaments: 'sanitaire/medicaments'
          }),
          traitementMedicaments(){
            let t = JSON.parse(this.object.medicaments)
            return t.sort((a,b) => a.order - b.order)
          }
      }
  
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>